import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]");

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

window.appHeight = appHeight;

const header = document.querySelector(".site-header");

const headerHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--header-height", `${header.offsetHeight}px`);
	console.log(header.offsetHeight);
};
window.addEventListener("resize", headerHeight);
headerHeight();

window.headerHeight = headerHeight;

var scrollLockEnabled = false;
let theNav = document.querySelector(".mobile-nav");
function shouldScrollLock() {
	if (!scrollLockEnabled) {
		disableBodyScroll(theNav);
	} else {
		enableBodyScroll(theNav);
	}
	scrollLockEnabled = !scrollLockEnabled;
}

window.shouldScrollLock = shouldScrollLock;

class InlineVideo {
	constructor(el) {
		this._el = el;
		this._video = el.dataset.video;
		this._image = el.querySelector(".js-video__placeholder");

		this._el.addEventListener("click", this.handleClick.bind(this));
	}

	handleClick() {
		this._el.insertAdjacentHTML(
			"beforeend",
			`<iframe src="${this._video}?autoplay=1&rel=0" frameborder="0"></iframe>`
		);
		this._image.classList.add("fade-out");

		setTimeout(() => {
			this._image.remove();
		}, 700);

		this._el.removeEventListener("click", this.handleClick);
	}
}

Array.from(document.querySelectorAll(".js-video")).forEach((video) => {
	new InlineVideo(video);
});

function getZoomLevel() {
	const width = window.innerWidth;
	if (width < 1024) {
		return 2.5;
	}
	return 3.75;
}

/*
Fancybox.bind('[data-fancybox="#clients-modal .client"]', {
	groupAll: true,
});
*/

import Rellax from "rellax";

function init() {
	let options = {
		speed: -2,
		center: true,
		wrapper: null,
		round: true,
		vertical: true,
		horizontal: false,
	};
	let rellax = new Rellax(".rellax", options);

	let rellax2 = new Rellax(".rellax2", {
		speed: -4,
		center: true,
		wrapper: null,
		round: true,
		vertical: true,
		horizontal: false,
	});

	let rellax3 = new Rellax(".rellax3", {
		speed: -6,
		center: true,
		wrapper: null,
		round: true,
		vertical: true,
		horizontal: false,
	});
}

import ScrollOut from "scroll-out";

ScrollOut({
	onShown(el) {
		el.classList.add("animated");
	},
});

document.addEventListener("DOMContentLoaded", () => {
	init();
});

/**
 * Form Cache Busting
 * Allows forms to be cached
 */
const forms = Array.from(document.querySelectorAll("form.fui-form"));
if (forms.length > 0) {
	updateForms(forms);
}

async function getCsrfToken() {
	const response = await fetch("/actions/users/session-info", {
		headers: {
			Accept: "application/json",
		},
	});
	const json = await response.json();
	return json;
}

async function updateForms(forms) {
	const token = await getCsrfToken();
	forms.map((f) => {
		const csrfInput = f.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
		csrfInput.value = token.csrfTokenValue;
	});
}
